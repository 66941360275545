
import Vue from "vue";
import { VueReCaptcha } from "vue-recaptcha-v3";
//@ts-ignore
import Password from "vue-password-strength-meter";
import SocialLogins from "@/components/Partials/SocialLogins.vue";
import { mapGetters } from "vuex";

Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_KEY });

export default Vue.extend({
  name: "SignUpForm",
  components: {
    Password,
    SocialLogins
  },
  props: {
    userType: String
  },
  data() {
    return {
      data: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        password_confirmation: "",
        type: "mentee"
      },
      errors: {},
      i_agree: false,
      btnLoading: false,
      passwordFieldType: "password"
    };
  },
  computed: {
    ...mapGetters({
      termsAndConditionLink: "helper/getTermsAndConditionLink"
    })
  },
  created() {
    if (this.$route.query.type == "mentor") {
      this.data.type = "mentor";
    }
  },
  methods: {
    async recaptcha() {
      this.btnLoading = true;

      //@ts-ignore
      await this.$recaptchaLoaded();

      //@ts-ignore
      const token = await this.$recaptcha("login");
      await this.onRegister(token);
    },
    switchPasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    async onRegister(token: string) {
      const data = {
        "g-recaptcha-response": token,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      };
      this.$store
        .dispatch("auth/SEND_OTP", { ...this.data, ...data })
        .then(() => {
          this.btnLoading = false;
          this.$router.push("/register/security-code");
          // @ts-ignore
          this.$alertify.success(
            (this as any).$t("setting.signUpFormOtpSentSuccessMessage")
          );
        })
        .catch(error => {
          this.btnLoading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    hasError(type: string) {
      //@ts-ignore
      if (type in this.errors && this.errors[type][0].length > 0) {
        //@ts-ignore
        return { error: this.errors[type][0] };
      }
      return null;
    }
  }
});
